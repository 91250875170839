html, body {
  padding: 0;
  margin: 0;
}

.dialog {

  &__content {
    padding: 20px;

    h3 {
      margin: 0;
    }

    p {
      margin-top: 5px;
    }
  }

  &__buttonBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;

    Button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}