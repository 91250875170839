.header{

    .primary.button.annotated.inverted {
        border: 1px solid #5a2483;
        color: #5a2483;
        background-color: rgba(168, 145, 192, 1.0);


        &:hover, &:active, &:focus {
            border: 1px solid white;
            color: white;
        }        
    }

    .secondary.button.inverted {
        border: 1px solid white;
        color: white;
        background-color: transparent;

        &:hover, &:active, &:focus {
            border: 1px solid white;
            color: #5a2483;
            background-color: white;
        }
    }

    @media (max-width: 320px) {

        .header_logo div {
            width: 114px;
            height: 60px;
            background-size: auto 40px;
        }
    }
}