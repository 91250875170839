.sidenavigation {

  .profileimage__image {
    cursor: pointer;
    width: initial;
  }
}

.sidenavigationmenu-button {
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  letter-spacing: 1px;
  border-radius: 0;
  font-size: 14px;
  border: none;
  color: #717171;
  min-height: 41px;
  line-height: 41px;
  text-align: left;
  padding: 0 0 0 20px;
  transition: background-color .15s linear;
  border-left: 4px solid transparent;

  display: flex;
  justify-content: flex-start;

  // &__icon {
  //   display: inline-block;
  //   width: 41px;
  //   height: 41px;
  //   overflow: hidden;
  // }

  // svg {
  //   position: relative;
  //   line-height: 41px;
  //   margin-right: 20px;
  // }

  &:hover, &.active, &:focus {
    color: white;
    border-left: 4px solid #7439A1;
    background: #ab7fcf;

    svg {
      fill: white !important;
    }
  }
}