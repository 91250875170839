@charset "UTF-8";
.notification_watcher {
  display: block;
  position: relative; }
  .notification_watcher__container {
    position: absolute;
    right: 10px;
    top: 4px;
    user-select: none;
    font-size: 75%; }
  .notification_watcher .item::after {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text; }
  .notification_watcher .item.green.blue.purple::after {
    content: "●●●";
    background-image: linear-gradient(to right, #FF6955 33%, #469944 33%, #E15AA4 67%, #9E5DAE 67%); }
