.profileimage {
  display: flex;
  width: 100%;
  overflow: hidden;
  border: 1px solid #DBE0E2;
  //background-color: #DBE0E2;
  
  &-is-missing-image {
    background-color: #DBE0E2;

    img {
      display: none;
    }
  }

  &--small {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;

    img {
      max-width: 80px;
      object-fit: cover;
    }
  }

  &--large {
    width: 228px;
    height: 228px;
    min-width: 228px;
    min-height: 228px;

    img {
      max-width: 228px;
      object-fit: cover;
    }
  }

  &__container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }

  &__image {
    width: 100%;
  }
}