// Bulma functions
@function mergeColorMaps($bulma-colors, $custom-colors) {
  // we return at least bulma hardcoded colors
  $merged-colors: $bulma-colors;

  // we want a map as input
  @if type-of($custom-colors) == 'map' {
    @each $name, $components in $custom-colors {
      // color name should be a string and colors pair a list with at least one element
      @if type-of($name) == 'string' and (type-of($components) == 'list' or type-of($components) == 'color') and length($components) >= 1 {
        $color-base: null;

        // the param can either be a single color
        // or a list of 2 colors
        @if type-of($components) == 'color' {
          $color-base: $components; }
        @else if type-of($components) == 'list' {
          $color-base: nth($components, 1); }

        $color-invert: null;
        // is an inverted color provided in the list
        @if length($components) > 1 {
          $color-invert: nth($components, 2); }

        // we only want a color as base color
        @if type-of($color-base) == 'color' {
          // if inverted color is not provided or is not a color we compute it
          @if type-of($color-invert) != 'color' {
            $color-invert: findColorInvert($color-base); }

          // we merge this colors elements as map with bulma colors (we can override them this way, no multiple definition for the same name)
          $merged-colors: map_merge($merged-colors, ($name: ($color-base, $color-invert))); } } } }

  @return $merged-colors; }

@function powerNumber($number, $exp) {
  $value: 1;
  @if $exp > 0 {
    @for $i from 1 through $exp {
      $value: $value * $number; } }
  @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: $value / $number; } }
  @return $value; }

@function colorLuminance($color) {
  $color-rgb: ('red': red($color),'green': green($color),'blue': blue($color));
  @each $name, $value in $color-rgb {
    $adjusted: 0;
    $value: $value / 255;
    @if $value < 0.03928 {
      $value: $value / 12.92; }
    @else {
      $value: ($value + .055) / 1.055;
      $value: powerNumber($value, 2); }
    $color-rgb: map-merge($color-rgb, ($name: $value)); }
  @return (map-get($color-rgb, 'red') * .2126) + (map-get($color-rgb, 'green') * .7152) + (map-get($color-rgb, 'blue') * .0722); }

@function findColorInvert($color) {
  @if (colorLuminance($color) > 0.55) {
    @return rgba(#000, 0.7); }
  @else {
    @return #fff; } }

// Bulma driven variables
$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: 0.75rem !default;
$radius: 4px !default;

// Default colours
$turquoise: hsl(171, 100%, 41%) !default;
$black:        hsl(0, 0%, 4%) !default;
$black-bis:    hsl(0, 0%, 7%) !default;
$black-ter:    hsl(0, 0%, 14%) !default;

$grey-darker:  hsl(0, 0%, 21%) !default;
$grey-dark:    hsl(0, 0%, 29%) !default;
$grey:         hsl(0, 0%, 48%) !default;
$grey-light:   hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;

$white-ter:    hsl(0, 0%, 96%) !default;
$white-bis:    hsl(0, 0%, 98%) !default;
$white:        hsl(0, 0%, 100%) !default;

$orange:       hsl(14,  100%, 53%) !default;
$yellow:       hsl(48,  100%, 67%) !default;
$green:        hsl(141, 71%,  48%) !default;
$turquoise:    hsl(171, 100%, 41%) !default;
$cyan:         hsl(204, 86%,  53%) !default;
$blue:         hsl(217, 71%,  53%) !default;
$purple:       hsl(271, 100%, 71%) !default;
$red:          hsl(348, 100%, 61%) !default;

$text: $grey-dark !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $grey-darker !default;


$primary: $turquoise !default;
$info: $cyan !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $white-ter !default;
$dark: $grey-darker !default;
$orange-invert: findColorInvert($orange) !default;
$yellow-invert: findColorInvert($yellow) !default;
$green-invert: findColorInvert($green) !default;
$turquoise-invert: findColorInvert($turquoise) !default;
$cyan-invert: findColorInvert($cyan) !default;
$blue-invert: findColorInvert($blue) !default;
$purple-invert: findColorInvert($purple) !default;
$red-invert: findColorInvert($red) !default;

$primary-invert: $turquoise-invert !default;
$info-invert: $cyan-invert !default;
$success-invert: $green-invert !default;
$warning-invert: $yellow-invert !default;
$danger-invert: $red-invert !default;
$light-invert: $dark !default;
$dark-invert: $light !default;
$link: $blue !default;
$link-invert: $blue-invert !default;
$link-visited: $purple !default;


$size-small: $size-7 !default;
$size-normal: $size-6 !default;
$size-medium: $size-5 !default;
$size-large: $size-4 !default;
$custom-colors: null;
$colors: mergeColorMaps(("white": ($white, $black), "black": ($black, $white), "light": ($light, $light-invert), "dark": ($dark, $dark-invert), "primary": ($primary, $primary-invert), "link": ($link, $link-invert), "info": ($info, $info-invert), "success": ($success, $success-invert), "warning": ($warning, $warning-invert), "danger": ($danger, $danger-invert)), $custom-colors) !default;

// Temporary definition from Bulma until migration
.field {
  &:not(:last-child) {
    margin-bottom: 0.75rem; } }


// Checkbox styling
$checkbox-radius: 0 !default;
$checkbox-border: .1rem solid $grey-lighter !default;
$checkbox-block-background: $white-ter !default;
$checkradio-focus: 1px dotted $grey-light !default;
$checkradio-top-offset: 0rem !default;

@mixin checkbox-size($size) {
  $newSize: $size * 1.5;
  $height: $newSize / 2.5;
  $width: $newSize / 4;

  + label {
    font-size: $size;
    // line-height: $newSize
    padding-left: $size * 2;
    &::before,
    &:before {
      width: $newSize;
      height: $newSize; }
    &::after,
    &:after {
      width: $width;
      height: $height;
      top: ( ( $newSize / 2 ) - ( $height / 2 ) ) * 0.9;
      left: $height; } }

  &.is-block {
    + label {
      &::after,
      &:after {
        top: ( ( ( $newSize / 2 ) - ( $height / 2 ) ) * 1.3 );
        left: $height * 1.3; } } }

  &.is-rtl {
    + label {
      padding-left: 0;
      padding-right: $size * 2;
      &::after,
      &:after {
        left: auto;
        right: $height; } } } }

// Use a mixin to define all ratios in the same spot
@mixin radio-size($size) {
  $newSize: $size * 1.5;
  $height: $newSize;
  $width: $newSize;

  + label {
    font-size: $size;
    line-height: $newSize;
    padding-left: $size * 2;
    &::before,
    &:before,
    &::after,
    &:after {
      width: $width;
      height: $height; } }
  &.is-rtl {
    + label {
      padding-right: $size * 2; } } }

.is-checkradio[type="radio"],
.is-checkradio[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0;

  + label {
    position: relative;
    display: initial;
    cursor: pointer;
    vertical-align: middle;
    margin: .5em;
    padding: .2rem .5rem .2rem 0;
    border-radius: $checkbox-radius;
    &:first-of-type {
      margin-left: 0; }

    &:hover {
      &::before,
      &:before {
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: hover-color; } }

    &::before,
    &:before {
      position: absolute;
      left: 0;
      top: $checkradio-top-offset;
      content: '';
      border: $checkbox-border; }

    &::after,
    &:after {
      position: absolute;
      display: none;
      content: '';
      top: $checkradio-top-offset; } }

  &.is-rtl {
    + label {
      margin-right: 0rem;
      margin-left: 0.5rem;
      &::before,
      &:before {
        left: auto;
        right: 0; } } }

  &:focus {
    + label {
      &::before,
      &:before {
        outline: $checkradio-focus; } } }

  &:hover:not([disabled]) {
    + label {
      &::before,
      &:before {
        border-color: $primary !important; } } }

  &:checked {
    + label {
      &::before,
      &:before {
        border: $checkbox-border; } }
    &[disabled] {
      cursor: not-allowed;
      + label {
        opacity: 0.5; } }
    + label {
      &::before,
      &:before {
        animation-name: none; }
      &::after,
      &:after {
        display: inline-block; } } }

  &[disabled] {
    cursor: not-allowed;
    + label {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover,
      &:before,
      &::before,
      &::after,
      &:after {
        cursor: not-allowed; } }

    &:hover {
      cursor: not-allowed;
      &::before,
      &:before {
        animation-name: none; } }

    &::before,
    &:before {
      cursor: not-allowed; }

    &::after,
    &:after {
      cursor: not-allowed; } }

  &.has-no-border {
    + label {
      &::before,
      &:before {
        border: none !important; } } }

  &.is-block {
    display: none !important;
    + label {
      width: 100% !important;
      background: $checkbox-block-background;
      color: findColorInvert( $checkbox-block-background );
      padding-right: .75em;
      &::before,
      &:before {
        border: none !important; } } } }

.is-checkradio[type="checkbox"] {
  + label {
    &::before,
    &:before {
      border-radius: $checkbox-radius; }

    &::after,
    &:after {
      box-sizing: border-box;
      transform: rotate(45deg);
      border-width: .1rem;
      border-style: solid;
      border-color: $primary;
      border-top: 0;
      border-left: 0; } }

  &.is-circle {
    + label {
      &::before,
      &:before {
        border-radius: 50%; } } }

  @include checkbox-size($size-normal);
  &.is-small {
    @include checkbox-size($size-small); }
  &.is-medium {
    @include checkbox-size($size-medium); }
  &.is-large {
    @include checkbox-size($size-large); }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      &.has-background-color {
        + label {
          &::before,
          &:before {
            border-color: transparent !important;
            background-color: $color !important; } } }
      &:hover:not([disabled]) {
        + label {
          &::before,
          &:before {
            border-color: $color !important; } } }
      &:checked {
        + label {
          &::after,
          &:after {
            border-color: $color !important; } }
        &.has-background-color {
          + label {
            &::before,
            &:before {
              border-color: transparent !important;
              background-color: $color !important; }
            &::after,
            &:after {
              border-color: $color-invert !important;
              background-color: $color !important; } } }
        &.is-block {
          + label {
            color: $color-invert;
            border-color: $color !important;
            background: $color;
            &::after,
            &:after {
              border-color: $color-invert !important; } } } } } }

  &:indeterminate {
    + label {
      &::after,
      &:after {
        display: inline-block;
        transform: rotate(90deg);
        border-bottom: none; } }
    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      $color-invert: nth($pair, 2);
      &.is-#{$name} {
        + label {
          &::after,
          &:after {
            border-color: $color; } } } } } }

.is-checkradio[type="radio"] {
  + label {
    &::before,
    &:before {
      border-radius: 50%; }
    &::after,
    &:after {
      border-radius: 50%;
      background: $primary;
      left: 0;
      transform: scale(0.5); } }

  &:checked {
    &.has-background-color {
      + label {
        &::before,
        &:before {
          border-color: $text !important;
          background-color: $text !important; }
        &::after,
        &:after {
          border-color: $text !important;
          background-color: $text !important; } } } }

  &.is-rtl {
    + label {
      padding-left: 0;
      &::after,
      &:after {
        left: auto;
        right: 0; } } }

  @include radio-size($size-normal);
  &.is-small {
    @include radio-size($size-small); }
  &.is-medium {
    @include radio-size($size-medium); }
  &.is-large {
    @include radio-size($size-large); }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      &.has-background-color {
        + label {
          &::before,
          &:before {
            border-color: $color !important;
            background-color: $color !important; } } }
      &:hover:not([disabled]) {
        + label {
          &::before,
          &:before {
            border-color: $color !important; } } }
      &:checked {
        + label {
          &::after,
          &:after {
            border-color: $color !important;
            background-color: $color !important; } }
        &.has-background-color {
          + label {
            &::before,
            &:before {
              border-color: $color !important;
              background-color: $color !important; }
            &::after,
            &:after {
              border-color: $color-invert !important;
              background-color: $color-invert !important; } } } } } } }
